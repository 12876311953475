import React, { useContext, useState, createContext, useEffect } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCode, faGamepad, faHeart, faMobile, faMoneyBillWave, faStar } from '@fortawesome/free-solid-svg-icons';
import ShatterdCellThumbnail from '../assets/images/shatterd-cell-thumbnail.jpg';
import TerryWeddingThumbnail from '../assets/images/terry-wedding-thumbnail.jpg';
import PakWeddingThumbnail from '../assets/images/pak-wedding-thumbnail.jpg';
import FlappyThumbnail from '../assets/images/ap-flappy-thumbnail.jpg';
import PortfolioThumbnail from '../assets/images/portfolio-thumbnail.jpg';
import APBudgetThumbnail from '../assets/images/ap-budget-thumbnail.jpg';
export interface FileType {
    filename: string;
    icon: IconProp;
    onOpen?: () => void;
    preview?: Preview;
};

export const EmptyFile: FileType = {
    filename: '',
    icon: faStar,
    preview: {
        title: '',
        thumbnailSrc: '',
        description: '',
        tags: [''],
        repo: '',
        href: ''
    }
};

export interface Preview {
    title: string;
    thumbnailSrc: string;
    description: string;
    tags: string[];
    repo?: string;
    href: string;
    download?: boolean;
    filesize?: string;
};

export interface Directory {
    directory: string;
    files: FileType[];
    folders: FolderArray;
    parent?: Directory;
};

interface FolderArray extends Array<Directory> { }

export type Directories = Directory[];

const data: Directories = [
    {
        directory: 'Desktop',
        files: [],
        folders: [
            {
                directory: 'Personal',
                files: [
                    {
                        filename: 'AP Budget',
                        icon: faMoneyBillWave,
                        preview: {
                            title: 'AP Budget',
                            thumbnailSrc: APBudgetThumbnail,
                            description: 'A simple and intuitive personal finance tool with user authentication via Google Firebase.',
                            tags: ['React', 'TypeScript', 'Firebase', 'Firestore', 'Styled Components'],
                            repo: 'https://gitfront.io/r/user-6612805/P2wgBYyLSact/ap-budget-app/',
                            href: 'https://ap-budget.com/'
                        }
                    },
                    {
                        filename: `Dev Portfolio`,
                        icon: faCode,
                        preview: {
                            title: `Dev Portfolio`,
                            thumbnailSrc: PortfolioThumbnail,
                            description: 'This contains the very site you are actively viewing. This is where all my projects will live. New features will continuously be implemented.',
                            tags: ['React JS', 'TypeScript', 'Styled Components'],
                            repo: 'https://gitfront.io/r/user-6612805/DUhR6pesFiTp/dev-portfolio/',
                            href: 'https://alexpak.dev/',
                        }
                    },
                    {
                        filename: `Pak Wedding`,
                        icon: faHeart,
                        preview: {
                            title: `Pak Wedding`,
                            thumbnailSrc: PakWeddingThumbnail,
                            description: 'Created for my own wedding, my goal with this site was to provide an intuitive interface for guests to view details and RSVP for themselves, as well as their guests.',
                            tags: ['React', 'TypeScript', 'Styled Components', 'Express'],
                            repo: 'https://github.com/alexlpak/joining-the-pak',
                            href: 'https://www.joiningthepak.com/',
                        }
                    },
                ],
                folders: []
            },
            {
                directory: 'Client Work',
                files: [
                    {
                        filename: `Shatter'd Cell Solutions`,
                        icon: faMobile,
                        preview: {
                            title: `Shatter'd Cell Solutions`,
                            thumbnailSrc: ShatterdCellThumbnail,
                            description: 'Created for a cell phone repair shop in Edmond, Oklahoma. The goal for this site was to redesign and simplify their site, focusing on user-experience.',
                            tags: ['React JS', 'Styled Components', 'React Router'],
                            href: 'https://shatterdcell.com/',
                            repo: 'https://gitfront.io/r/user-6612805/MdNWFR3qMNex/shatterd-cell-solutions/',
                        }
                    },
                    {
                        filename: `Terry Wedding`,
                        icon: faHeart,
                        preview: {
                            title: `Terry Wedding`,
                            thumbnailSrc: TerryWeddingThumbnail,
                            description: 'Created for a couple who got married January, 2020. This website introduces the couple to users, as well as providing an intuitive RSVP process that links with a backend database that was setup in Google\'s Firebase Realtime DB.',
                            tags: ['jQuery', 'Firebase'],
                            href: 'https://terry-wedding.netlify.app/',
                            repo: 'https://github.com/alexlpak/terry-wedding'
                        }
                    },
                ],
                folders: []
            },

            {
                directory: 'Games',
                files: [
                    {
                        filename: `Flappy Bird`,
                        icon: faGamepad,
                        preview: {
                            title: `Flappy Bird`,
                            thumbnailSrc: FlappyThumbnail,
                            description: 'A simple "Flappy Bird" clone using Phaser JS and TypeScript.',
                            tags: ['Phaser JS', 'TypeScript'],
                            repo: 'https://github.com/alexlpak/ap-flappy',
                            href: 'https://ap-flappy.netlify.app/',
                        }
                    }
                ],
                folders: []
            }
        ]
    }
];

interface FileSystemContextType {
    files: Directories;
    setFiles: React.Dispatch<React.SetStateAction<Directories>>;
};

const FileSystemContext = createContext<FileSystemContextType>({} as FileSystemContextType);

export const useFileSystemContext = () => {
    return useContext(FileSystemContext);
};

interface FileSystemContextProviderProps {
    children: React.ReactNode | React.ReactNode[];
};

export const FileSystemContextProvider: React.FC<FileSystemContextProviderProps> = ({ children }) => {
    const [files, setFiles] = useState(data);

    const addParentToObject = (directory: Directory) => {
        directory.folders.forEach(folder => {
            folder.parent = directory;
            addParentToObject(folder);
        });
    };
    
    const addParentToFiles = (directories: Directories) => {
        directories.forEach(directory => {
            addParentToObject(directory);
        });
    };

    useEffect(() => {
        addParentToFiles(files);
        // eslint-disable-next-line
    }, [files]);

    const value = {
        files, setFiles,
    };

    return (
        <FileSystemContext.Provider value={value}>
            {children}
        </FileSystemContext.Provider>
    );
};